* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* custome scrollbar css */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e6e6e6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e6e6e6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb {
  background: #c5c5c5;
}
