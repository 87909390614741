.card2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    border-radius: 24px;
    line-height: 1.6;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 36px;
    border-radius: 22px;
    color: #ffffff;
    overflow: hidden;
    background: rgb(65,167,75);
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content2::before {
    position: absolute;
    content: "";
    top: -4%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%);
    background: rgb(65,177,90);
    z-index: -1;
    transform-origin: bottom;
  
    border-radius: inherit;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content2::after {
    position: absolute;
    content: "";
    top: -8%;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translate(-50%);
    background:  rgb(65,188,100);
    z-index: -2;
    transform-origin: bottom;
    border-radius: inherit;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content2 svg {
    width: 48px;
    height: 48px;
  }
  
  .content2 .para {
    z-index: 1;
    opacity: 1;
    color:white;
    font-weight: 500;
    font-size: 18px;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content2 .link {
    z-index: 1;
    color:  #41a74b;
    text-decoration: none;
    font-family: inherit;
    font-size: 16px;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .content2 .link:hover {
    text-decoration: underline;
  }
  
  .card2:hover {
    transform: translate(0px, -16px);
  }
  
  .card2:hover .content2::before {
    rotate: -8deg;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .card2:hover .content2::after {
    rotate: 8deg;
    top: 0;
    width: 100%;
    height: 100%;
  }
  