@import "./variable.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-Thin.eot");
  src: url("./assets/fonts/BRCandor-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-Thin.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-Thin.woff") format("woff"),
    url("./assets/fonts/BRCandor-Thin.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-Thin.svg#BRCandor-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-ThinItalic.eot");
  src: url("./assets/fonts/BRCandor-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-ThinItalic.woff") format("woff"),
    url("./assets/fonts/BRCandor-ThinItalic.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-ThinItalic.svg#BRCandor-ThinItalic")
      format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-ExtraLight.eot");
  src: url("./assets/fonts/BRCandor-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-ExtraLight.woff") format("woff"),
    url("./assets/fonts/BRCandor-ExtraLight.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-ExtraLight.svg#BRCandor-ExtraLight")
      format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-ExtraLightItalic.eot");
  src: url("./assets/fonts/BRCandor-ExtraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-ExtraLightItalic.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-ExtraLightItalic.woff") format("woff"),
    url("./assets/fonts/BRCandor-ExtraLightItalic.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-ExtraLightItalic.svg#BRCandor-ExtraLightItalic")
      format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-Light.eot");
  src: url("./assets/fonts/BRCandor-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-Light.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-Light.woff") format("woff"),
    url("./assets/fonts/BRCandor-Light.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-Light.svg#BRCandor-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-LightItalic.eot");
  src: url("./assets/fonts/BRCandor-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-LightItalic.woff") format("woff"),
    url("./assets/fonts/BRCandor-LightItalic.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-LightItalic.svg#BRCandor-LightItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-RegularItalic.eot");
  src: url("./assets/fonts/BRCandor-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-RegularItalic.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-RegularItalic.woff") format("woff"),
    url("./assets/fonts/BRCandor-RegularItalic.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-RegularItalic.svg#BRCandor-RegularItalic")
      format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-Regular.eot");
  src: url("./assets/fonts/BRCandor-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-Regular.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-Regular.woff") format("woff"),
    url("./assets/fonts/BRCandor-Regular.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-Regular.svg#BRCandor-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-Medium.eot");
  src: url("./assets/fonts/BRCandor-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-Medium.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-Medium.woff") format("woff"),
    url("./assets/fonts/BRCandor-Medium.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-Medium.svg#BRCandor-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-MediumItalic.eot");
  src: url("./assets/fonts/BRCandor-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-MediumItalic.woff") format("woff"),
    url("./assets/fonts/BRCandor-MediumItalic.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-MediumItalic.svg#BRCandor-MediumItalic")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-SemiBold.eot");
  src: url("./assets/fonts/BRCandor-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-SemiBold.woff") format("woff"),
    url("./assets/fonts/BRCandor-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-SemiBold.svg#BRCandor-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-SemiBoldItalic.eot");
  src: url("./assets/fonts/BRCandor-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-SemiBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-SemiBoldItalic.woff") format("woff"),
    url("./assets/fonts/BRCandor-SemiBoldItalic.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-SemiBoldItalic.svg#BRCandor-SemiBoldItalic")
      format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-Bold.eot");
  src: url("./assets/fonts/BRCandor-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-Bold.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-Bold.woff") format("woff"),
    url("./assets/fonts/BRCandor-Bold.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-Bold.svg#BRCandor-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-BoldItalic.eot");
  src: url("./assets/fonts/BRCandor-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-BoldItalic.woff") format("woff"),
    url("./assets/fonts/BRCandor-BoldItalic.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-BoldItalic.svg#BRCandor-BoldItalic")
      format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-Black.eot");
  src: url("./assets/fonts/BRCandor-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-Black.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-Black.woff") format("woff"),
    url("./assets/fonts/BRCandor-Black.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-Black.svg#BRCandor-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BR Candor";
  src: url("./assets/fonts/BRCandor-BlackItalic.eot");
  src: url("./assets/fonts/BRCandor-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BRCandor-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/BRCandor-BlackItalic.woff") format("woff"),
    url("./assets/fonts/BRCandor-BlackItalic.ttf") format("truetype"),
    url("./assets/fonts/BRCandor-BlackItalic.svg#BRCandor-BlackItalic")
      format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
input::-ms-reveal {
  display: none;
}
body {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  background-color: $c_highlight;
  margin: 0;
  font-family: $f_Poppins;

  & * {
    font-family: $f_Poppins !important;
  }
}

h1,
h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: $c_heading;
  margin: 0;
}

p {
  color: $c_text;
}

img {
  max-width: 100%;
}

// Common
.anchor_link {
  color: $c_primary;
  transition: $transition;
  cursor: pointer;

  &:hover {
    color: $c_primary_hover;
  }
}

:is(.gap_p, .gap_m) {
  --gap_x: 30px;
  --gap_y: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gap_y) * -1);
  margin-left: calc(var(--gap_x) * -1);

  &.aic {
    align-items: center;
  }

  &.jcsb {
    justify-content: space-between;
  }

  & > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-top: var(--gap_y);
  }
}

.gap_p > * {
  padding-left: var(--gap_x);
}

.gap_m > * {
  margin-left: var(--gap_x);
}

.w {
  &_25 {
    width: 25%;
  }

  &_50 {
    width: 50%;
  }

  &_100 {
    width: 100%;
  }
}

.text_center {
  text-align: center;
}

.cursor_p {
  cursor: pointer;
}

// Button
.MuiButton-root {
  &.btn {
    height: 48px;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    box-shadow: none;
    text-transform: capitalize;
    gap: 5px;

    &:hover {
      box-shadow: none;
    }

    svg {
      font-size: 20px;
    }
  }

  &.btn_square {
    min-width: 48px;
    padding: 5px;
  }

  &.btn_primary {
    color: $c_white;
    background-color: $c_primary;

    &:hover {
      background-color: $c_secondary;
    }
  }

  &.btn_secondary {
    color: $c_white;
    background-color: $c_secondary;

    &:hover {
      background-color: $c_primary;
    }
  }

  &.btn_orange {
    color: $c_white;
    background-color: $c_orange;

    &:hover {
      background-color: $c_orange;
    }
  }

  &.btn_white {
    color: $c_black;
    background-color: $c_white;

    &:hover {
      color: $c_white;
      background-color: $c_secondary;
    }

    &.br {
      border: 1px solid #ebebeb;

      &:hover {
        color: $c_black;
        background-color: $c_highlight;
      }
    }
  }

  &.btn_highlight {
    color: #030302;
    background-color: $c_highlight;

    &:hover {
      color: $c_white;
      background-color: $c_secondary;
    }
  }

  &.btn_danger {
    color: $c_white;
    background-color: $c_red;

    &:hover {
      background-color: $c_red_hover;
    }
  }

  &.sm {
    height: 35px;
    padding: 5px 10px;
  }
}
.text_field textarea {
  color: black;
  font-weight: 500;
  height: 50px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px !important;
  border: 1px solid $c_black;
  box-shadow: none !important;
  background-color: #fff;
  padding: 10px;
}
.text_field p,
.err_msg {
  color: red !important;
  font-size: 14px !important;
  margin: 0 !important;
  font-weight: 400;
}
// Forms
.custom_label[class*="MuiTypography"],
label {
  font-size: 14px;
  font-weight: 400;
  color: #ababab;
  margin-bottom: 5px;
  letter-spacing: 0;
  line-height: 1.2;
  display: block;
}

.MuiFormControl-root,
.MuiTextField-root,
.react-tel-input {
  > .MuiInputBase {
    &-root {
      position: relative;
      padding: 0;

      &::before,
      &::after {
        display: none;
      }
    }

    &-hiddenLabel {
      > fieldset {
        display: none;
      }
    }
  }

  .react-tel-input .form-control {
    width: 100% !important;
  }

  :is(input, select, textarea, input.form-control) {
    color: $c_black;
    font-weight: 400;
    height: 56px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 5px !important;
    border: 1px solid $c_form_border;
    box-shadow: none !important;
    transition: $transition;

    &:focus {
      border-color: $c_secondary;
    }

    &::placeholder {
      color: #ababab;
      opacity: 1;
      font-weight: 400;
    }
  }

  :is(input, select) {
    padding: 5px 20px;
  }

  :is(textarea) {
    padding: 15px 20px;
  }

  input:has(+ .eye_btn) {
    padding-right: 50px;
  }

  .eye_btn {
    position: absolute;
    height: 50px;
    max-height: 50px;
    width: 50px;
    margin: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: 80%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 auto;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.MuiFormControlLabel-root {
  color: $c_black;
  line-height: 1.2;
  margin: 0 !important;
  gap: 10px;

  .MuiCheckbox-root {
    padding: 0;
    color: $c_form_border;
    position: relative;
    top: -1px;

    &.Mui-checked {
      color: $c_primary;
    }
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    padding: 5px;
    font-weight: 500;
    display: inline-flex;
    letter-spacing: 0;
  }
}

.MuiCheckbox-root {
  padding: 0 !important;

  input {
    z-index: 3;
  }

  &::before {
    content: "";
    width: 22px;
    height: 22px;
    border-radius: 6px;
    background-color: #f3f3f3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    position: relative;
    display: block;
    z-index: 1;
  }

  &.Mui-checked {
    color: $c_secondary !important;

    &::before {
      background-color: $c_primary;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="%23fff"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
    }
  }

  svg {
    display: none;
  }
}

.MuiRadio-root {
  padding: 0 !important;

  input {
    z-index: 3;
  }

  &::before {
    content: "";
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #f3f3f3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    position: relative;
    display: block;
    z-index: 1;
  }

  &.Mui-checked {
    color: $c_secondary !important;

    &::before {
      background-color: $c_primary;
      background-image: url('data:image/svg+xml,<svg height="100" width="100" fill="%23ffffff" xmlns="http://www.w3.org/2000/svg"><circle r="45" cx="50" cy="50" fill="white" /></svg>');
    }
  }

  svg {
    display: none;
  }
}

.MuiInputBase-root {
  .MuiSelect-select {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: $c_black;
    border-radius: 5px;
    border: 1px solid #ededed;
    transition: $transition;
    outline: none !important;
    box-sizing: border-box;
    box-shadow: none;
    height: 56px !important;
    padding: 0 40px 0 20px !important;
    line-height: 56px;
    background-color: $c_white;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23717171' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 11px 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
    appearance: none;

    &:not([aria-expanded="true"]) {
      border-color: #ededed;
    }

    &[aria-expanded="true"] {
      border-color: $c_secondary;
    }

    &:has(+ input[value="default"]) {
      color: #ababab;
    }

    & ~ fieldset,
    & ~ .MuiSvgIcon-root {
      display: none;
    }

    &:focus {
      border-radius: 5px;
    }
  }
}

.form_btn {
  margin-top: 30px;

  &.end {
    text-align: right;
  }
}

.upload_input {
  cursor: pointer;
  position: relative;

  .MuiFormControl-root {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #030302;
    height: 56px;
    padding: 0 20px;
    border: 1px solid #ededed;
    border-radius: 5px;
    cursor: pointer;
  }
}

#menu- {
  .MuiPaper-root {
    margin-top: 5px;
    box-shadow: $shadow2 !important;
    border-radius: 10px !important;

    .MuiList-root {
      padding: 10px;

      .MuiButtonBase-root {
        font-size: 13px;
        line-height: 1.25;
        color: $c_black;
        font-weight: 400;
        min-height: 36px;
        padding: 10px 10px;
        border-radius: 4px;
        white-space: normal;

        &:hover {
          background-color: $c_highlight;
        }

        &.Mui-disabled {
          display: none;
        }

        &.Mui-selected {
          background-color: $c_highlight;
        }
      }
    }
  }
}

.MuiAutocomplete {
  &-popper {
    > div {
      margin-top: 5px;
      box-shadow: $shadow2 !important;
      border-radius: 10px !important;
    }

    .MuiAutocomplete {
      &-listbox {
        padding: 10px;
      }

      &-option {
        font-size: 13px;
        line-height: 1.25;
        color: $c_black;
        font-weight: 400;
        min-height: 36px !important;
        padding: 10px 10px !important;
        border-radius: 4px !important;
        white-space: normal;
        display: flex;

        &.Mui-focused,
        &:hover {
          background-color: $c_highlight !important;
        }
      }
    }
  }
}

.react-tel-input {
  .country-list {
    padding: 0;

    .search-box {
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 5px;
      line-height: normal;
      padding: 5px 10px;
      width: 100%;
      margin: 0;
      border: 1px solid $c_form_border;
    }

    li {
      &.search {
        padding: 10px 10px 5px 10px;
      }

      &.country {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 10px 8px 40px;
      }

      .flag {
        left: 10px;
        top: 4px;
        margin: 0;
      }
    }
  }
}

// Modal
.modal {
  overflow-x: hidden;
  overflow-y: auto;

  .MuiBackdrop-root {
    background-color: #030302;
    opacity: 0.5 !important;
  }

  .modal-dialog {
    width: auto;
    display: flex;
    align-items: center;
    min-height: calc(100% - (20px * 2));
    margin: 20px auto;
    position: relative;
    max-width: 550px;
    outline: none;
  }

  .modal-body {
    width: 100%;
    padding: 35px 40px 40px;
    background-color: $c_white;
    border-radius: 10px;
    position: relative;
    border: none;
    overflow-wrap: anywhere;
  }

  .modal_title {
    margin-bottom: 20px;

    h2 {
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .btn-close {
    top: 20px;
    right: 20px;
    position: absolute;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    opacity: 1;

    svg {
      font-size: 22px;
      color: #292d32;
      transition: $transition;
    }

    &:hover {
      svg {
        color: $c_red;
      }
    }
  }

  .footer_btn_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 10px;

    .MuiButton-root.btn {
      height: 54px;
      min-width: 150px;
      padding: 5px 20px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      border-radius: 10px;
    }
  }

  &.passport_modal,
  &.vehicleRegistration_modal {
    .modal-dialog {
      max-width: 585px;
    }

    .modal_title {
      h2 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
        color: #2c2c2c;
        font-weight: 500;
        margin-block: 15px 20px;
      }

      .btn_flex {
        display: flex;
        align-items: center;
        margin-top: 15px;
        gap: 15px;

        .MuiButton-root.btn {
          height: 40px;
          padding: 5px 20px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0px;
          border-radius: 100px;
          gap: 10px;

          img {
            transition: $transition;
          }

          &:hover {
            img {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }
  }

  &.passport_modal {
    .passport_img {
      display: flex;
      justify-content: center;

      img {
        width: 60%;
        height: 60%;
        margin: 10px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: $shadow2;
        border: 1px solid #c0c0c0;
      }
    }
  }

  &.vehicleRegistration_modal {
    .modal_title {
      p {
        span {
          display: block;
          color: #717171;
          font-weight: 400;
          margin-top: 3px;
        }
      }
    }

    .vehicle_img {
      margin-bottom: 65px;

      &.gap_m {
        --gap_x: 15px;
        --gap_y: 15px;
      }

      figure {
        text-align: center;
        width: calc(100% / 3 - var(--gap_x));

        img {
          border-radius: 8px;
        }

        figcaption {
          font-size: 14px;
          display: block;
          font-weight: 500;
          line-height: 1.2;
          margin-top: 10px;
        }
      }
    }

    .registration_img {
      display: flex;

      img {
        width: 100%;
      }
    }
  }

  &.datepiker_modal {
    .modal-dialog {
      max-width: 450px;
    }

    .modal_title {
      h2 {
        font-size: 18px;
        margin-bottom: 5px;
      }

      p {
        color: #787878;
      }
    }

    .footer_btn_flex {
      margin-top: 15px;

      .MuiButton-root.btn {
        width: 100%;
      }
    }

    &.editDeparture_modal {
      .MuiPickersLayout-root {
        .MuiPickersToolbar-root {
          display: none;
        }
      }
    }

    &#editDepartureDateModal {
      .MuiPickersLayout-root .MuiDateRangePickerDay-root .Mui-selected,
      .MuiPickersLayout-root .MuiDayCalendar-weekContainer .Mui-selected {
        background-color: $c_primary;
      }

      .footer_btn_flex {
        .MuiButton-root.btn_secondary {
          color: #fff;
          background-color: $c_primary;
        }
      }
    }
  }

  &.fcDetail_modal {
    .modal-dialog {
      max-width: 920px;
    }

    .modal-body {
      padding: 25px 30px 30px;

      .table_container {
        margin-top: 30px;
      }
    }
  }

  &.complaintDetail_modal {
    .complaint_reason {
      p {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }

  &.remark_modal {
    .remark_detail {
      .user_block {
        display: inline-flex;
        align-items: center;
        margin-bottom: 15px;
        gap: 10px;

        figure {
          height: 42px;
          min-width: 42px;
          width: 42px;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        p {
          font-size: 14px;
          color: $c_heading;
          font-weight: 500;
        }
      }

      > p {
        color: $c_text_grey;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }

  &.addRole_modal {
    .modal-dialog {
      max-width: 585px;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      color: #030302;
      margin-block: 30px 20px;
    }

    .roles_list {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:first-child {
          padding-bottom: 20px;
          margin-bottom: 20px;
        }

        &:not(:last-child) {
          padding-bottom: 15px;
          margin-bottom: 15px;
        }

        &:first-child {
          border-bottom: 1px dashed #ededed;
        }

        p {
          font-size: 12px;
          font-weight: 500;
          color: #030302;
        }

        .MuiFormGroup-root {
          display: flex;
          flex-direction: row;
          gap: 40px;
        }

        .MuiFormControlLabel-root {
          .MuiFormControlLabel-label {
            font-weight: 400;
            color: #ababab;

            &:hover {
              color: $c_black;
            }
          }
        }
      }
    }

    .footer_btn_flex {
      border-top: 1px solid #ededed;
      justify-content: flex-end;
      padding-top: 30px;
    }
  }
  &.addRole_modal2 {
    .modal-dialog {
      max-width: 585px;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      color: #030302;
      margin-block: 30px 20px;
    }

    .roles_list {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:first-child {
          padding-bottom: 20px;
          margin-bottom: 20px;
        }

        &:not(:last-child) {
          padding-bottom: 15px;
          margin-bottom: 15px;
        }

        p {
          font-size: 13px;
          font-weight: 500;
          color: #030302;
        }

        .MuiFormGroup-root {
          display: flex;
          flex-direction: row;
          gap: 40px;
        }

        .MuiFormControlLabel-root {
          .MuiFormControlLabel-label {
            font-weight: 400;
            color: #ababab;

            &:hover {
              color: $c_black;
            }
          }
        }
      }
    }

    .footer_btn_flex {
      border-top: 1px solid #ededed;
      justify-content: flex-end;
      padding-top: 30px;
    }
  }

  &.addUser_modal {
    .modal_title {
      h2 {
        font-size: 26px;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #030302;
      margin-block: 25px 20px;
    }

    .footer_btn_flex {
      justify-content: flex-end;
      margin-top: 25px;
    }
  }

  &.addNotification_modal {
    .modal_title {
      h2 {
        font-size: 26px;
      }
    }
    .err_msg {
      margin-left: "5px";
      color: red !important;
      font-size: 14px !important;
      margin: 0 !important;
      font-weight: 400;
    }

    .form {
      .control_group {
        margin-bottom: 10px;
      }
    }

    .footer_btn_flex {
      justify-content: flex-end;
      margin-top: 25px;
    }
  }

  &.userRating_modal {
    .modal-dialog {
      max-width: 400px;
      text-align: center;
    }

    .modal-body {
      padding: 40px 35px;

      figure {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;

        img {
          width: 98px;
          height: 98px;
          object-fit: cover;
          border: 4px solid $c_white;
          outline: 3px solid $c_secondary;
          border-radius: 50%;
        }
      }

      h2 {
        font-weight: 600;
      }

      p {
        color: #717171;
      }

      .MuiRating-root {
        margin: 15px 0 25px;
        color: #42a74b !important;

        label {
          color: #42a74b !important;
          margin: 0;
        }

        .MuiSvgIcon-root {
          font-size: 30px;
        }

        .MuiRating-iconEmpty {
          color: rgba(66, 167, 75, 0.4) !important;
        }
      }
    }
  }

  &.addComment_modal {
    .modal-dialog {
      max-width: 500px;
    }

    .form {
      .control_group {
        margin-bottom: 10px;
      }
    }

    .footer_btn_flex {
      margin-top: 15px;
      justify-content: flex-end;
      margin-bottom: 50px;

      .MuiButton-root.btn {
        height: 45px;
        min-width: 100px;
        font-size: 12px;
      }
    }
  }

  &.shipmentSummary_modal {
    .modal-dialog {
      max-width: 1030px;
    }

    .table_container {
      table {
        .user_block {
          p {
            font-size: 12px;
            color: $c_grey;

            b {
              font-size: 13px;
              color: $c_black;
              font-weight: 500;
              margin-bottom: 2px;
            }
          }

          figure {
            height: 34px;
            min-width: 34px;
            width: 34px;
          }
        }

        &.v2 {
          tr {
            :is(th) {
              font-size: 12px;
              text-transform: uppercase;
              padding: 15px 12px;
            }

            :is(td) {
              font-size: 12px;
              font-weight: 400;
              padding: 8px 12px;
              color: $c_grey;
            }
          }
        }
      }
    }
  }

  &.giveFeedback_modal {
    .modal-dialog {
      max-width: 465px;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      + p {
        color: #787878;
      }

      .MuiRating-root {
        color: $c_secondary !important;

        label {
          color: $c_secondary !important;
          margin: 0;
        }

        .MuiSvgIcon-root {
          font-size: 25px;
        }

        .MuiRating-iconEmpty {
          color: #dcdcdc !important;
        }
      }
    }

    .footer_btn_flex {
      justify-content: flex-end;

      .MuiButton-root.btn {
        min-width: 65px;
      }
    }
  }

  &.deleteReview_modal {
    .modal-dialog {
      max-width: 385px;
    }

    .modal_title {
      text-align: center;

      h2 {
        font-weight: 600;
        margin-bottom: 5px;
      }

      p {
        font-size: 14px;
        color: #717171;
      }
    }

    .footer_btn_flex {
      .MuiButton-root.btn {
        height: 48px;
        min-width: 1px;
        font-size: 14px;
        width: 100%;
      }
    }
  }

  &.editReview_modal {
    .modal-dialog {
      max-width: 600px;
    }

    .form {
      .control_group {
        margin-bottom: 15px;
      }

      .MuiRating-root {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $c_secondary !important;
        margin: 0 auto 10px;
        gap: 5px;

        label {
          color: $c_secondary !important;
          margin: 0;
        }

        .MuiSvgIcon-root {
          font-size: 40px;
        }

        .MuiRating-iconEmpty {
          color: #dcdcdc !important;
        }
      }
    }

    .footer_btn_flex {
      justify-content: flex-end;

      .MuiButton-root.btn {
        height: 48px;
        min-width: 1px;
        font-size: 14px;
      }
    }
  }

  &.viewComment_modal {
    .modal-dialog {
      max-width: 510px;
    }

    .modal_title {
      h2 {
        font-size: 22px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: #717171;
      }
    }
  }

  &.shippingGallery_modal {
    .modal-dialog {
      max-width: 710px;
    }

    .modal_title {
      h2 {
        font-size: 22px;
      }
    }
  }
}

.custom_accordion {
  .MuiAccordion-root {
    box-shadow: none;
    background-color: transparent;

    &:not(:first-child) {
      margin-top: 10px;
    }

    &::before {
      display: none;
    }

    .MuiAccordionSummary-root {
      padding: 0;
      min-height: 52px;
      background-color: $c_highlight;
      border-radius: 10px;
      padding: 5px 15px 5px 20px;

      .MuiAccordionSummary-content {
        margin: 0;
        font-size: 15px;
        font-weight: 500;
        color: #030302;
      }

      .MuiAccordionSummary-expandIconWrapper {
        background-color: $c_white;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #292d32;
        transform: rotate(-90deg);
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }

    &.Mui-expanded {
      margin: 10px 0 0;

      .MuiAccordionSummary-root {
        .MuiAccordionSummary-expandIconWrapper {
          transform: rotate(0deg);
        }
      }
    }
  }

  .profileInfo_list {
    padding: 20px;
  }
}

.fcustomer_info {
  &.gap_m {
    --gap_x: 20px;
  }

  .left_s {
    width: calc(48% - var(--gap_x));

    h2 {
      margin-bottom: 15px;
    }
  }

  .right_s {
    width: calc(52% - var(--gap_x));
  }

  .fcImg_list {
    list-style: none;

    &.gap_p {
      --gap_x: 10px;
      --gap_y: 10px;
    }

    li {
      width: calc(100% / 4);

      figure {
        width: 100%;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 7px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .fcComment_box {
    padding: 15px;
    border-radius: 8px;
    background-color: $c_highlight;
    max-height: 145px;
    overflow: auto;

    h2 {
      font-size: 14px;
      margin-block: -15px 5px;
      margin-inline: -15px;
      padding: 15px;
      background-color: $c_highlight;
      position: sticky;
      z-index: 1;
      top: -15px;
    }
  }
}

.fcComment_list {
  li {
    display: flex;
    gap: 6px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    figure {
      height: 30px;
      min-width: 30px;
      width: 30px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    p {
      font-size: 11px;
      color: #717171;

      b {
        color: $c_black;
        display: block;
        margin-bottom: 2px;
        font-size: 12px;
        font-weight: 500;
      }

      span {
        display: block;
      }
    }
  }

  &.v2 {
    padding-right: 5px;
    max-height: 260px;
    overflow: auto;

    li {
      gap: 8px;

      figure {
        height: 40px;
        min-width: 40px;
        width: 40px;
      }

      p {
        font-size: 11px;

        b {
          font-size: 13px;
        }
      }
    }
  }
}

// Sidebar
.sidebar-main-div {
  .sidebar_drawer {
    .MuiDrawer-paper {
      background-color: $c_grey_dark;
      border: none;
    }
  }

  .sidebar-logo {
    padding: 20px 15px 15px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $c_grey_dark;
    z-index: 9;
    text-align: left;
    min-height: 100px;

    figure {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;

      img {
        height: 65px;
        max-height: 100%;
        max-width: 100%;
        cursor: pointer;
      }
    }
  }

  .sidebr-lst {
    padding: 0 15px 20px;

    .lst-itm {
      padding: 5px 15px;
      min-height: 46px;
      border-radius: 12px;
      transition: $transition;
      gap: 10px;

      &:not(:last-child) {
        margin-bottom: 0;
      }

      .MuiListItemIcon-root {
        min-width: 22px;

        img {
          width: 100%;
          transition: $transition;
        }
      }

      .lstitm-txt {
        margin: 0;
        color: $c_grey;
        display: inline-flex;
        transition: $transition;

        span {
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 1.2;
        }

        + svg {
          color: #aeaeae;
          font-size: 18px;
          transform-origin: center;
          transition: $transition;
        }
      }

      &:hover,
      &.Mui-selected {
        .MuiListItemIcon-root {
          img {
            filter: brightness(0) invert(1);
          }
        }

        .lstitm-txt {
          color: $c_white;

          + svg {
            color: $c_white;
          }
        }
      }

      &.Mui-selected {
        background-color: #2c2c2c;
        pointer-events: none;
      }
    }

    .MuiCollapse-root {
      .MuiListItemButton-root {
        align-items: center;
        padding: 5px 15px 5px 45px;
        min-height: 38px;
        border-radius: 12px;
        transition: $transition;
        margin-top: 1px;
        gap: 10px;

        &::before {
          content: "";
          width: 5px;
          height: 5px;
          display: inline-flex;
          border: 1.5px solid $c_grey;
          transition: $transition;
          border-radius: 50%;
          position: relative;
          top: -1px;
        }

        .MuiListItemText-root {
          margin: 0;
          color: $c_grey;
          display: inline-flex;
          transition: $transition;

          .MuiTypography-root {
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 1.2;
          }
        }

        &:hover,
        &.Mui-selected {
          &::before {
            border-color: $c_white;
          }

          .MuiListItemText-root {
            color: $c_white;
          }
        }

        &.Mui-selected {
          &::before {
            border-color: $c_white;
          }

          background-color: #2c2c2c;
        }
      }
    }
  }
}

// Topbar
.site_header {
  .topbar {
    box-shadow: none;
    background-color: $c_white;
    border-bottom: 1px solid #ededed;

    .MuiToolbar-root {
      min-height: 82px;
      padding: 10px 25px;
      box-sizing: border-box;
    }
  }

  .topbar_left {
    display: flex;
    align-items: center;

    .btn {
      height: 40px;
      padding: 5px 16px;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0;
    }
  }

  .topbar_user_btn {
    display: flex;
    align-items: center;
    padding: 0;
    border-radius: 0;
    background-color: transparent !important;

    img {
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      background-color: #f9f9f9;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      color: #555;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-left: 3px;
    }
  }

  .icon_btn {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transition: $transition;

    &:hover {
      background-color: $c_highlight;
    }
  }

  .chat_btn {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: 10px;
      top: 10px;
      width: 7px;
      height: 7px;
      background-color: $c_secondary;
      border-radius: 50%;
      display: inline-flex;
      opacity: 0;
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }
  }
}

.userDropdown_menu {
  .userDropdown_menu_list {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    min-width: 150px;

    button {
      padding: 0;
      pointer-events: none;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}

.notiDropdown_menu {
  .MuiMenu-paper {
    max-width: 450px;
    overflow-y: auto;
    box-shadow: $shadow3;
    filter: none;
  }

  ul.MuiList-padding {
    padding: 0;
    min-width: 235px;
  }

  .noti_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px 15px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $c_white;
    z-index: 2;

    h2 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      color: $c_black;
    }

    .btn {
      height: 30px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .noti_list {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 240px;
    overflow: auto;

    li {
      display: flex;
      align-items: flex-start;
      padding: 10px 15px;
      gap: 10px;
      cursor: pointer;
      transition: $transition;

      &:hover {
        background-color: $c_highlight;
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      figure {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h3 {
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 2px;
        color: $c_black;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 15px;

        span {
          font-size: 12px;
          font-weight: 500;
          margin: 0;
          color: $c_text_grey;
        }
      }

      p {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        color: #555;
      }
    }
  }
}

// Main
.main_content {
  .sub-layout {
    padding: calc(82px + 20px) 25px 25px;
    max-width: 100% !important;
  }
}

.cards {
  padding: 20px 25px;
  box-shadow: $shadow2;
  background-color: $c_white;
  border-radius: 15px;
  overflow: initial;
}

.cards_header,
.table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 30px;

  .left_s {
    h2 {
      font-weight: 600;
      line-height: 1.2;
      font-family: $f_Candor !important;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    p {
      font-size: 12px;
      font-weight: 500;
      font-family: $f_Candor !important;
    }
  }

  .right_s {
    display: inline-flex;
    align-items: center;
    gap: 30px;

    p {
      font-size: 12px;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      font-family: $f_Candor !important;
      gap: 20px;

      span {
        cursor: pointer;
        transition: $transition;

        &:hover {
          color: $c_secondary;
        }
      }
    }

    .MuiButton-root.btn {
      height: 40px;
      padding: 5px 16px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      border-radius: 8px;
      gap: 10px;

      &.fw_400 {
        font-weight: 400;
      }

      img {
        transition: $transition;
      }

      &:hover {
        img {
          filter: brightness(0) invert(1);
        }
      }

      &.btn_secondary {
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

.table_header {
  margin-bottom: 25px;

  .left_s {
    display: inline-flex;
    align-items: center;
    gap: 20px;
  }

  .right_s {
    gap: 10px;

    .MuiButton-root.btn {
      height: 48px;
    }
  }

  &.select_filter {
    gap: 45px;

    .d_flex {
      width: 100%;
      display: flex;
      gap: 14px;

      > .MuiInputBase-root,
      > .MuiFormControl-root,
      > .MuiAutocomplete-root {
        width: calc(100% / 4 - (14px * 3 / 4));
        flex: 1;
      }
    }

    .MuiFormControl-root {
      .MuiFormLabel-root {
        margin: 0;
        color: #717171;
        font-size: 13px;
        width: auto;
        line-height: 1;
        top: 15px;
        left: 15px;
        transform: none;
        padding: 3px 5px;
        background-color: $c_white;
        border-radius: 8px;

        &.Mui-focused,
        &.MuiFormLabel-filled {
          top: -8px;
        }
      }

      .MuiInputBase-root {
        border: 1px solid $c_white;
        background-color: $c_white;
        border-radius: 10px;

        input {
          height: 48px;
          font-size: 13px;
          border: none;
          background: transparent;
          border-radius: 0 !important;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23717171' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
          background-position: right 15px center;
          background-repeat: no-repeat;
          background-size: 11px 11px;
        }
      }

      .MuiInputAdornment-root {
        margin: 0;

        .MuiIconButton-root {
          position: absolute;
          inset: 0;
          z-index: 1;
          opacity: 0;
        }
      }

      fieldset {
        display: none;
      }
    }

    .MuiAutocomplete {
      &-root {
        .MuiInputBase-root {
          padding: 0;
          border: none;
          background-color: transparent;

          input {
            color: $c_black;
            border: 1px solid $c_white;
            padding: 5px 40px 5px 20px;
            background-color: $c_white;
            border-radius: 10px !important;

            &::placeholder {
              color: #717171;
            }

            &:focus {
              border-color: $c_secondary;
            }
          }
        }

        .MuiOutlinedInput-notchedOutline {
          display: none;
        }

        &.MuiAutocomplete-hasClearIcon,
        &.MuiAutocomplete-hasPopupIcon {
          .MuiInputBase-root {
            padding: 0 !important;
          }
        }
      }

      &-endAdornment {
        .MuiButtonBase-root {
          background-color: $c_white;
          transition: $transition;

          &:hover {
            color: $c_red;
          }
        }

        .MuiAutocomplete-popupIndicator {
          display: none;
        }
      }
    }

    .MuiButton-root.btn {
      border-radius: 8px;

      img {
        height: 24px;
        filter: brightness(0) invert(1);
      }
    }
  }

  .MuiInputBase-root {
    .MuiSelect-select {
      font-size: 13px;
      border-radius: 10px;
      border: 1px solid #e2e2e2;
      height: 48px !important;
      line-height: 48px;

      &:not([aria-expanded="true"]) {
        border-color: $c_white;
      }

      &[aria-expanded="true"] {
        border-color: $c_secondary;
      }

      &:has(+ input[value="default"]) {
        color: #717171;
      }
    }
  }
}

.main_title {
  margin-bottom: 20px;

  h1 {
    margin-bottom: 1px;
  }

  p {
    color: #717171;

    span {
      cursor: pointer;
      transition: $transition;

      &:hover {
        color: $c_secondary;
        text-decoration: underline;
      }
    }
  }

  &.d_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right_s {
      .MuiButton-root.btn {
        height: 40px;
        padding: 5px 16px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        border-radius: 8px;
        box-shadow: $shadow3;
        gap: 10px;

        &.fw_400 {
          font-weight: 400;
        }

        img {
          transition: $transition;
        }

        &:hover {
          img {
            filter: brightness(0) invert(1);
          }
        }

        &.btn_secondary {
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}

.table_container {
  margin-top: 20px;

  table {
    width: 100%;

    tr {
      :is(th, td) {
        font-size: 12px;
        font-weight: 500;
        color: $c_text;
        line-height: 1.3;
        letter-spacing: 0;
        border: none;
        padding: 10px 15px;
        white-space: normal;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      th {
        color: #717171;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        border-bottom: 1px solid #ededed;
        padding: 17px 15px;
        white-space: nowrap;
      }

      td {
        font-family: $f_Candor !important;

        &.max_c {
          p {
            max-width: 250px;
            font-family: $f_Candor !important;
            line-height: 1.5;
          }
        }

        &.max_c2 {
          white-space: nowrap;

          p {
            font-family: $f_Candor !important;
            line-height: 1.5;
          }
        }

        &.double_value {
          span {
            display: block;

            &:not(:first-child) {
              margin-top: 5px;
            }
          }
        }
      }
    }

    .user_img {
      margin: 0;

      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    .user_block {
      vertical-align: middle;
      display: inline-flex;
      align-items: center;
      gap: 20px;

      figure {
        height: 46px;
        min-width: 46px;
        width: 46px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      p {
        font-size: 13px;
        font-family: $f_Candor !important;

        b {
          font-size: 14px;
          color: #464e5f;
          font-weight: 600;
          display: block;
          margin-bottom: 5px;
          white-space: nowrap;
        }
      }
    }

    .MuiCheckbox-root {
      padding: 0;
    }

    .star_rating {
      margin: 0;
      vertical-align: middle;
    }

    .status_btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: 30px;
      min-width: 72px;
      border-radius: 6px;
      font-size: 11px;
      font-weight: 600;
      font-family: $f_Candor !important;
      background-color: $c_highlight;

      padding: 4px 5px 2px;
      line-height: 1;
      border: none;

      &.c_warning {
        color: #ffa800;
        background-color: #fff4de;
      }

      &.c_black {
        color: #000000;
        background-color: #fff4de;
      }

      &.c_success {
        color: #1bc5bd;
        background-color: #c9f7f5;
      }
    }

    .btn_text {
      min-height: 34px;
      min-width: 100px;
      border-radius: 6px;
      border: none;
      outline: none;
      padding: 5px 15px 3px;
      font-size: 13px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      white-space: nowrap;
      letter-spacing: 0;

      &.c_highlight {
        color: $c_black;
        background-color: $c_highlight;
      }

      &.c_success {
        color: $c_success;
        background-color: $c_success_bg;
      }

      &.c_info {
        color: $c_info;
        background-color: $c_info_bg;
      }

      &.c_warning {
        color: $c_warning;
        background-color: $c_warning_bg;
      }
      &.c_black {
        color: #000000;
        background-color: #fff4de;
      }
      &.c_blue {
        color: blue;
        background-color: $c_red_bg;
      }
      &.c_danger {
        color: $c_red;
        background-color: $c_red_bg;
      }
    }

    .icon_text {
      vertical-align: middle;
      display: inline-flex;
      align-items: center;
      gap: 5px;

      img {
        top: -2px;
        position: relative;
      }
    }

    &.v2,
    &.v3 {
      tbody {
        tr {
          border-bottom: 1px solid #ededed;
        }
      }

      tr {
        :is(th, td) {
          font-size: 13px;
          font-weight: 400;
          padding: 15px 15px;

          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }
        }

        th {
          font-weight: 600;
          color: $c_black;
          text-transform: capitalize;
          border-bottom: none;
          padding: 12px 15px;
          background-color: $c_highlight;
        }

        td {
          font-family: $f_Poppins !important;
        }
      }
    }

    &.v3 {
      tr {
        td {
          color: #717171;

          p {
            color: #717171;
          }
        }
      }
    }
  }

  .MuiFormControl-root {
    select {
      height: 35px;
      padding: 2px 20px 2px 10px !important;

      & + svg {
        font-size: 20px;
        right: 0;
      }
    }
  }
}

.table_actions {
  display: inline-flex;
  align-items: center;
  gap: 10px;

  button {
    padding: 0;
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 6px;
    background-color: $c_highlight;
    transition: $transition;

    img {
      transition: $transition;
    }

    &.round_btn {
      border-radius: 50%;
    }

    &:hover,
    &[aria-expanded="true"] {
      background-color: $c_secondary;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &.v2 {
    button {
      background-color: transparent;

      &:hover,
      &[aria-expanded="true"] {
        background-color: $c_secondary;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  &.v3 {
    gap: 5px;

    button {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}

.sorting_table {
  .MuiDataGrid-root {
    border: none;

    .MuiDataGrid-main {
      .MuiDataGrid-columnHeaders {
        border: none;
        border-bottom: 1px solid #ededed;
        background-color: #f3f3f3;

        .MuiDataGrid-columnHeader {
          border: none;
          outline: none;

          &:not(.MuiDataGrid-columnHeaderCheckbox) {
            padding: 10px 10px;
          }

          .MuiDataGrid-columnHeaderTitle {
            color: #717171;
            font-size: 13px;
            font-weight: 500;
            text-transform: uppercase;
            border-bottom: 1px solid #ededed;
            white-space: nowrap;
          }

          .MuiCheckbox-root:not(.Mui-checked)::before {
            content: "";
            background-color: $c_white;
          }

          &[aria-sort="none"] {
            .MuiDataGrid-iconButtonContainer {
              .MuiIconButton-root {
                &::after {
                  background-image: url("../public/static/images/sorting_arrow.svg");
                }
              }
            }
          }

          &[aria-sort="ascending"] {
            .MuiDataGrid-iconButtonContainer {
              .MuiIconButton-root {
                &::after {
                  background-image: url("../public/static/images/sortingAcending_arrow.svg");
                }
              }
            }
          }

          &[aria-sort="descending"] {
            .MuiDataGrid-iconButtonContainer {
              .MuiIconButton-root {
                &::after {
                  background-image: url("../public/static/images/sortingDecending_arrow.svg");
                }
              }
            }
          }
        }
      }

      .MuiDataGrid-virtualScroller {
        .MuiDataGrid-row {
          // max-height: initial !important;
          background-color: transparent !important;

          &:not(:last-child) {
            border-bottom: 1px solid #ededed;
          }
        }

        .MuiDataGrid-cell {
          border: none;
          outline: none;
          // height: auto;
          // max-height: initial !important;

          &:not(.MuiDataGrid-cellCheckbox) {
            padding: 12px 10px;
          }

          .MuiDataGrid-cellContent,
          p,
          .text_icon {
            font-size: 13px;
            font-weight: 400;
            color: #717171;
            line-height: 1.3;
            letter-spacing: 0;
            white-space: normal;

            &.c_primary {
              color: $c_primary;
              width: 100%;
              text-align: center;
            }
            &.c_warning {
              color: $c_warning;
              background-color: $c_warning_bg;
            }
            &.c_danger {
              color: $c_red;
            }
            &.c_blue {
              color: blue;
              background-color: $c_red_bg;
            }
            &.c_black {
              color: $c_black;
            }
          }

          .user_block {
            vertical-align: middle;
            display: inline-flex;
            align-items: center;
            gap: 14px;

            figure {
              height: 42px;
              min-width: 42px;
              width: 42px;
              border-radius: 50%;
              overflow: hidden;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            p,
            > span:not(.MuiRating-root) {
              color: #717171;
              font-size: 13px;
              font-family: $f_Candor !important;

              b {
                font-size: 14px;
                color: $c_black;
                font-weight: 400;
                display: block;
                margin-bottom: 2px;
                white-space: nowrap;
                font-family: $f_Candor !important;
              }
            }

            p.flexD {
              display: flex;
              flex-direction: column;
            }
          }

          .btn_text {
            min-height: 34px;
            min-width: 100px;
            border-radius: 6px;
            border: none;
            outline: none;
            padding: 5px 15px 3px;
            font-size: 13px;
            font-weight: 500;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            letter-spacing: 0;

            &.c_highlight {
              color: $c_black;
              background-color: $c_highlight;
            }

            &.c_success {
              color: $c_success;
              background-color: $c_success_bg;
            }

            &.c_success2 {
              color: #14208c;
              background-color: rgba(89, 232, 137, 0.2);
            }

            &.c_info {
              color: $c_info;
              background-color: $c_info_bg;
            }

            &.c_warning {
              color: $c_warning;
              background-color: $c_warning_bg;
            }
            &.c_black {
              color: #000000;
              background-color: #fff4de;
            }
            &.c_danger {
              color: $c_red;
              background-color: $c_red_bg;
            }
            &.c_blue {
              color: blue;
              background-color: $c_red_bg;
            }

            &.c_pink {
              color: #f400f9;
              background-color: rgba(244, 0, 249, 0.2);
            }
          }

          .view_link {
            color: $c_primary;
            text-decoration: underline;
            cursor: pointer;
          }

          .departure_date {
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            gap: 5px;

            .MuiIconButton-root {
              padding: 0;

              img {
                height: 16px;
                filter: brightness(0);
              }
            }
          }
        }
      }

      .MuiDataGrid-menuIcon,
      .MuiDataGrid-columnSeparator {
        display: none;
      }

      .MuiDataGrid-iconButtonContainer {
        margin-left: 4px;

        .MuiButtonBase-root {
          padding: 0;
          position: relative;
          display: flex;
          top: -1px;

          &:hover {
            background-color: transparent;
          }

          &::after {
            content: "";
            height: 14px;
            width: 14px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: block;
          }

          svg {
            font-size: 14px;
            display: none;
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }
      }
    }

    .MuiDataGrid-footerContainer {
      border: none;
    }
  }

  .text_icon {
    padding: 0;
    background-color: transparent !important;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    transition: $transition;

    .MuiTouchRipple-root {
      display: none;
    }

    img {
      transition: $transition;
      position: relative;
      top: -1px;
    }

    &:hover {
      color: $c_black !important;

      img {
        filter: brightness(0);
      }
    }
  }
}

.stats_boxes {
  &.gap_m {
    --gap_x: 20px;
    --gap_y: 20px;
  }

  .cards {
    position: relative;
    width: calc(100% / 4 - var(--gap_x));
    padding: 20px 20px 20px 85px;
    transition: $transition;
    cursor: pointer;

    figure {
      width: 50px;
      height: 50px;
      border-radius: 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $c_highlight;
      position: absolute;
      left: 20px;
      top: 20px;
    }

    h2 {
      font-size: 22px;
      font-weight: 600;
      line-height: 1.3;
      margin-bottom: 5px;
      font-family: $f_Candor !important;
      transition: $transition;
    }

    p {
      color: #717171;
      font-family: $f_Candor !important;
      line-height: 1.35;
    }

    &:hover {
      transform: translateY(-3px);

      h2 {
        color: $c_orange;
      }
    }
  }
}

.grid_sc {
  padding-top: 20px;

  &.gap_m {
    --gap_x: 20px;
    --gap_y: 20px;
  }

  .cards {
    width: calc(100% - var(--gap_x));
  }

  .g_rating {
    width: calc(41% - var(--gap_x));

    h2 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      font-family: $f_Candor !important;
      margin-bottom: 40px;
      gap: 10px;
    }

    .d_flex {
      display: flex;
      justify-content: space-between;

      .left_s {
        width: 40%;

        h3 {
          font-size: 44px;
          font-weight: 500;
          font-family: $f_Candor !important;
          margin-bottom: 12px;
          line-height: 1;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          color: $c_heading;
          font-family: $f_Candor !important;
        }
      }

      .right_s {
        width: 54%;

        p {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 2px;
          }

          b {
            min-width: 25px;
            font-size: 14px;
            display: inline-block;
            color: $c_heading;
            font-weight: 500;
          }

          .MuiLinearProgress-root {
            width: 100%;
            height: 7px;
            background-color: #ededed;
            border-radius: 15px;

            .MuiLinearProgress-bar {
              border-radius: 15px;
              background-color: $c_secondary;
            }
          }
        }
      }
    }
  }

  .s_duration {
    width: calc(59% - var(--gap_x));

    .cards_header {
      .left_s {
        h2 {
          font-size: 17px;
          font-weight: 600;
          display: inline-flex;
          align-items: center;
          line-height: 1.5;
          font-family: $f_Poppins !important;
          gap: 4px;

          img {
            position: relative;
            top: -1px;
          }
        }
      }
    }

    .duration_list {
      &.gap_p {
        --gap_x: 20px;
        --gap_y: 20px;
      }

      li {
        display: flex;
        align-items: center;
        width: calc(100% / 3);
        gap: 14px;

        figure {
          min-width: 40px;
          display: inline-flex;

          img {
            width: 100%;
          }
        }

        p {
          font-weight: 500;

          b {
            color: $c_black;
            font-weight: 600;
            display: block;
          }

          span {
            font-size: 12px;
            display: block;
          }
        }
      }
    }
  }
}

.star_rating {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: #dedede;
  gap: 2px;

  svg {
    font-size: 20px;

    &.active {
      color: #edce29;
    }
  }
}

.table_card {
  .table_container {
    margin-top: 0;

    + .text_center {
      margin-top: 10px;

      a {
        font-size: 12px;
        font-weight: 600;
        color: $c_secondary;
        text-decoration: none;
        font-family: $f_Candor !important;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.setting_menu {
  .MuiMenu-list {
    padding: 8px 4px;
    align-items: flex-start;
    flex-direction: column;
    min-width: 100px;

    .MuiButtonBase-root {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      padding: 5px 16px;
      color: #717171;
      border-radius: 4px;
      letter-spacing: 0;

      &:hover {
        color: $c_heading;
        background-color: $c_highlight;
      }
    }
  }

  &.v2 {
    .MuiMenu-list {
      padding: 10px 8px;
      min-width: 145px;

      .MuiButtonBase-root {
        font-size: 13px;
        padding: 8px 20px;
        color: $c_black;
      }
    }
  }
}

.search_box {
  display: flex;
  align-items: center;
  gap: 15px;

  .btn {
    height: 48px;
    font-size: 13px;
    border-radius: 10px;
    font-weight: 300;
  }

  .search_bar {
    width: 240px;
  }
}

.search_bar {
  width: 300px;
  position: relative;

  .search_icon {
    position: absolute;
    width: auto;
    height: auto;
    margin: 0;
    max-height: initial;
    left: 20px;

    img {
      position: relative;
      top: -1px;
    }
  }

  .MuiFormControl-root {
    input {
      height: 48px;
      color: $c_black;
      font-size: 13px;
      padding-left: 50px;
      border: 1px solid $c_highlight;
      background-color: $c_highlight;
      border-radius: 10px !important;
      transition: $transition;

      &:focus {
        border-color: $c_secondary;
      }

      &::placeholder {
        color: #717171;
        opacity: 1;
      }
    }
  }

  .cross_btn {
    position: absolute;
    right: 10px;
  }
}

.c_white {
  .search_bar {
    .MuiFormControl-root {
      input {
        border: 1px solid $c_white;
        background-color: $c_white;

        &:focus {
          border-color: $c_secondary;
        }
      }
    }
  }
}

.filter_menu {
  .MuiMenu-list {
    padding: 15px 20px 20px;
    align-items: flex-start;
    flex-direction: column;
    min-width: 100px;
    padding-right: 26px !important;
    &:has(.export_btns.v2) {
      padding: 10px;
    }

    .MuiButtonBase-root {
      display: block;
      padding: 0;
      background-color: transparent;
      cursor: auto;
      letter-spacing: 0;

      h2 {
        font-size: 16px;
        margin-bottom: 3px;
        color: $c_black;
      }

      p {
        font-size: 13px;
        color: #717171;
      }

      .MuiTouchRipple-root {
        display: none;
      }

      &.export_btns {
        margin-top: 10px;

        button {
          font-size: 15px;
          font-weight: 500;
          color: #717171;
          min-height: 42px;
          border-radius: 6px;
          padding: 5px 20px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          gap: 10px;

          &:hover {
            color: $c_black;
            background-color: $c_highlight;
          }
        }

        .round {
          display: inline-flex;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 1px solid #ededed;

          &.c_success {
            background-color: #50cd89;
          }

          &.c_grey {
            background-color: #f5f8fa;
          }

          &.c_warning {
            background-color: #c8ba3d;
          }
          &.c_black {
            color: #000000;
            background-color: #0077ff;
          }
          &.c_danger {
            background-color: #ed3333;
          }
          &.c_blue {
            color: blue;
            background-color: $c_red_bg;
          }
        }

        &.v2 {
          margin-top: 0;

          button {
            font-weight: 400;
            text-transform: capitalize;
            min-width: 155px;
          }
        }
      }

      &.checkbox_group {
        margin-top: 20px;
        max-width: 350px;

        &:has(.MuiFormControlLabel-root.w_100) {
          max-width: 230px;
        }

        .gap_m {
          --gap_x: 10px;
          --gap_y: 10px;
          flex-direction: row;
        }

        .MuiFormControlLabel-root {
          width: calc(50% - var(--gap_x));
          margin-top: var(--gap_y) !important;
          margin-left: var(--gap_x) !important;
          line-height: 1;

          &.w_100 {
            width: calc(100% - var(--gap_x));
          }

          .MuiFormControlLabel-label {
            font-size: 13px;
            font-weight: 400;
            color: #717171;
            text-transform: capitalize;
            white-space: normal;
          }
        }
      }
    }
  }
}

.location_box {
  display: flex;
  gap: 10px;

  .MuiButton-text {
    font-size: 13px;
    font-weight: 400;
    color: #717171;
    background-color: transparent !important;
    display: inline-flex;
    align-items: center;
    padding: 0;
    gap: 10px;

    span:not(.MuiTouchRipple-root) {
      color: #717171;
      width: 175px;
      min-height: 48px;
      border-radius: 10px;
      display: inline-flex;
      align-items: center;
      text-transform: capitalize;
      justify-content: flex-start;
      background-color: $c_highlight;
      letter-spacing: 0;
      padding: 5px 20px;
    }
  }

  .MuiIconButton-root {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    min-width: 48px;
    border-radius: 10px;
    background-color: $c_highlight;

    img {
      filter: brightness(0);
    }
  }
}

.MuiPickersLayout-root {
  .MuiPickersToolbar-root {
    padding: 0;

    > span.MuiTypography-root.MuiTypography-overline {
      display: none;
    }
  }

  .MuiDateRangePickerToolbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;

    h5 {
      display: none;
    }

    .MuiButtonBase-root {
      width: 100%;
      min-height: 48px;
      border: 1px solid #ededed;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 20px;

      span {
        color: #ababab;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .MuiDateRangeCalendar-root {
    width: 100%;

    > div[style] {
      display: none;
    }
  }

  .MuiDayCalendar-header {
    .MuiDayCalendar-weekDayLabel {
      width: 44px;
      height: 44px;
      min-width: 44px;
    }
  }

  .MuiDateRangeCalendar-monthContainer {
    width: 100%;
    margin-top: 20px;

    .MuiPickersCalendarHeader-root {
      margin: 10px 0;
      padding: 0;

      .MuiPickersCalendarHeader-label {
        font-size: 17px;
      }
    }
  }

  .MuiDateRangePickerDay-root,
  .MuiDayCalendar-weekContainer {
    .MuiPickersDay-root {
      width: 44px;
      height: 44px;
      min-width: 44px;
    }

    .Mui-selected {
      background-color: $c_secondary;
    }
  }

  .MuiPickersSlideTransition-root {
    min-height: 305px;
  }

  .MuiDateRangePickerDay-rangeIntervalDayHighlight {
    background-color: rgba(66, 167, 75, 0.14);
  }

  .MuiDialogActions-root {
    display: none;
  }

  .MuiDateCalendar-root {
    width: 100%;
  }
}

.custom_tabs {
  .custom_tabs_links {
    margin-bottom: 20px;
    min-height: 1px;

    .MuiTabs-flexContainer {
      display: inline-flex;
      gap: 50px;

      + .MuiTabs-indicator {
        display: none;
      }

      button {
        min-height: 1px;
        padding: 0 0 5px;
        font-size: 14px;
        font-weight: 500;
        color: #717171;
        max-width: initial;
        text-transform: capitalize;
        background-color: transparent;
        line-height: normal;
        letter-spacing: 0;
        border-radius: 0;
        transition: $transition;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 2px;
          background-color: $c_primary;
          transition: $transition;
        }

        &:hover {
          color: $c_black;
        }

        &.Mui-selected {
          color: $c_black;

          &::before {
            width: 40px;
          }
        }
      }
    }
  }

  [role="tabpanel"] {
    > .MuiBox-root {
      padding: 0;
    }
  }
}

.custom_tabs1 {
  .custom_tabs_links {
    min-height: 1px;

    .MuiTabs-flexContainer {
      display: inline-flex;
      background-color: $c_white;
      border-radius: 6px;
      padding: 5px;
      gap: 2px;

      + .MuiTabs-indicator {
        display: none;
      }

      button {
        min-height: 45px;
        padding: 0 20px;
        font-size: 13px;
        font-weight: 400;
        color: $c_black;
        text-transform: capitalize;
        border-radius: 6px;
        line-height: normal;
        transition: $transition;
        letter-spacing: 0;

        &:hover {
          color: $c_black;
          background-color: rgb(32 78 51 / 10%);
        }

        &.Mui-selected {
          color: $c_white;
          background-color: $c_primary;
          box-shadow: $shadow2;
        }
      }
    }
  }

  [role="tabpanel"] {
    > .MuiBox-root {
      padding: 0;
    }
  }
}

.custom_tabs2 {
  margin: 15px 0;

  .custom_tabs_links {
    min-height: 1px;

    .MuiTabs-flexContainer {
      gap: 2px;

      + .MuiTabs-indicator {
        display: none;
      }

      button {
        min-height: 50px;
        padding: 0 20px;
        font-size: 14px;
        font-weight: 500;
        color: #767676;
        text-transform: capitalize;
        border-radius: 8px;
        line-height: normal;
        transition: $transition;
        letter-spacing: 0;

        &:hover {
          color: $c_black;
        }

        &.Mui-selected {
          color: $c_white;
          background-color: $c_secondary;
        }
      }
    }
  }

  [role="tabpanel"] {
    > .MuiBox-root {
      padding: 0;
    }
  }
}

.MuiRating-root {
  color: #fac817 !important;

  label {
    color: #fac817 !important;
    margin: 0;
  }

  .MuiSvgIcon-root {
    font-size: 14px;
  }

  .MuiRating-iconEmpty {
    color: #d5d5d5 !important;
  }

  &.v2 {
    .MuiSvgIcon-root {
      font-size: 18px;
    }
  }
}

.report_page {
  .table_header {
    margin: 20px 0;
    gap: 15px;

    .d_flex {
      width: calc(67% - 8px);
      margin-right: auto;
    }
  }

  > .gap_m {
    --gap_x: 20px;
    --gap_y: 20px;
    padding-top: 25px;

    .left_s {
      width: calc(67% - var(--gap_x));
    }

    .right_s {
      width: calc(33% - var(--gap_x));
    }

    :is(.left_s, .right_s) {
      .cards {
        padding: 20px;
        box-shadow: $shadow3;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }

  .earned_card {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      gap: 30px;

      > * {
        font-family: $f_Candor !important;
      }

      strong {
        color: #030302;
        font-weight: 500;
      }

      span {
        text-decoration: underline;
        color: $c_secondary;
        font-weight: 600;
      }
    }

    ul {
      margin-top: 5px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 0;
        font-size: 13px;
        gap: 20px;

        > * {
          font-family: $f_Candor !important;
        }

        span {
          color: $c_grey;
          display: inline-flex;
          align-items: center;
          gap: 5px;
        }

        strong {
          font-weight: 500;
          color: $c_black;
        }
      }
    }
  }

  .doughnut_card {
    ul {
      margin-bottom: 15px;
      flex-wrap: wrap;
      display: flex;
      gap: 20px;

      li {
        font-size: 13px;
        font-weight: 500;
        list-style: none;
        color: $c_grey;

        strong {
          font-weight: 500;
          color: $c_black;
        }
      }
    }

    figure {
      text-align: center;
    }
  }

  .country_card {
    .d_flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      h2 {
        margin-bottom: 3px;
      }

      .MuiIconButton-root {
        padding: 0;
        width: 32px;
        height: 32px;
        min-width: 32px;
        border-radius: 6px;
        background-color: $c_highlight;
        transition: $transition;

        img {
          transition: $transition;
        }

        &:hover,
        &[aria-expanded="true"] {
          background-color: $c_secondary;

          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }

    ul {
      margin-top: 15px;

      li {
        display: flex;
        align-items: center;
        gap: 8px;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        figure {
          height: 50px;
          min-width: 50px;
          width: 50px;
          background-color: $c_highlight;
          border-radius: 6px;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 80%;
            max-height: 80%;
          }
        }

        h2 {
          font-size: 14px;
          font-weight: 600;
          display: inline-flex;
          flex-direction: column;
          font-family: $f_Candor !important;
          margin-right: auto;
          gap: 4px;

          span {
            color: #81de39;
            font-size: 13px;
            font-weight: 500;
            font-family: $f_Candor !important;
            line-height: 1;
          }
        }

        p {
          min-height: 28px;
          border-radius: 5px;
          padding: 5px 7px 5px 5px;
          font-size: 11px;
          font-weight: 500;
          white-space: nowrap;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 14px;
          }
          &.c_orange {
            color: #ff9100;
            background-color: #fff2df;
          }
          &.c_warning {
            color: $c_warning;
            background-color: $c_warning_bg;
          }
          &.c_danger {
            color: #ff1616;
            background-color: #fff2df;
          }
          &.c_blue {
            color: blue;
            background-color: $c_red_bg;
          }
          &.c_success {
            color: #81de39;
            background-color: #e8ffd7;
          }
        }
      }
    }
  }

  .worldMap_card {
    .head_flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      gap: 20px;

      .left {
        h2 {
          font-size: 19px;
          font-weight: 600;
          font-family: $f_Candor !important;
          color: #030302;
          line-height: 1.4;
        }
      }

      .search_bar {
        width: 220px;

        .MuiFormControl-root input {
          border: 1px solid #ededed;
          background-color: $c_white;
        }
      }
    }
  }

  .weightsBreakdown_card {
    h2 {
      font-size: 19px;
      font-weight: 600;
      font-family: $f_Candor !important;
      color: #030302;
      line-height: 1.4;
      margin-bottom: 25px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 28px;
      gap: 14px;

      li {
        min-width: 60px;
        min-height: 60px;
        background-color: $c_highlight;
        border-radius: 8px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;

        p {
          font-size: 11px;
          font-weight: 500;
          color: $c_black;
        }

        &.active {
          border-bottom: 4px solid $c_secondary;

          img {
            filter: brightness(0%) invert(68%) sepia(11%) saturate(2306%)
              hue-rotate(75deg) brightness(79%) contrast(92%);
          }
        }
      }
    }
  }
}

.reportStats_boxes {
  &.gap_m {
    --gap_x: 20px;
    --gap_y: 20px;
  }

  .cards {
    padding: 20px;
    position: relative;
    transition: $transition;
    box-shadow: $shadow3;

    h2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.3;
      margin-bottom: 2px;
      font-family: $f_Candor !important;
      transition: $transition;
    }
  }

  .orderCard {
    width: calc(33% - var(--gap_x));

    h2 {
      font-size: 26px;
    }

    figure {
      margin-top: 25px;
      display: flex;

      img {
        width: 100%;
      }
    }
  }

  .presentationCard {
    width: calc(67% - var(--gap_x));
  }

  .presentation_list {
    padding-top: 25px;

    &.gap_p {
      --gap_x: 10px;
      --gap_y: 10px;
    }

    li {
      display: flex;
      align-items: center;
      width: calc(100% / 3);
      gap: 10px;

      figure {
        min-width: 48px;
        display: inline-flex;

        img {
          width: 100%;
        }
      }

      p {
        b {
          font-size: 14px;
          color: $c_black;
          font-weight: 500;
          display: block;
        }

        span {
          display: block;
        }
      }
    }
  }
}

.roles_list {
  &.gap_m {
    --gap_x: 20px;
    --gap_y: 20px;
  }

  .cards {
    // padding: 30px 35px 35px;
    width: calc(100% / 3 - var(--gap_x));

    h2 {
      color: #030302;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: #2c2c2c;
    }

    ul {
      margin-block: 15px 20px;

      li {
        font-size: 14px;
        color: #717171;
        list-style-position: inside;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }

    .btn_flex {
      display: flex;
      gap: 12px;

      .MuiButtonBase-root.btn {
        font-size: 13px;
        font-weight: 600;
        min-height: 38px;
        border-radius: 8px;
        letter-spacing: 0;
      }
    }
  }
}

.upload_box {
  margin-top: 10px;
  margin-bottom: 20px;

  label {
    width: 90px;
    position: relative;
    display: inline-block;

    figure {
      width: 100%;
      height: 90px;
      object-fit: cover;
      border-radius: 6px;
      background-color: $c_highlight;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .crossIcon {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #ededed;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      border: 1px solid $c_white;
      transition: $transition;
      padding: 0;

      &:hover {
        color: $c_white;
        background-color: $c_red;
      }

      svg {
        font-size: 14px;
      }
    }

    .uploadIcon {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      background-color: $c_primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 5px;
      bottom: 5px;
      transform: translate(50%, 50%);
      border: 1px solid $c_white;
      padding: 6px;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.broadcast_grid {
  .cards {
    height: 100%;
  }

  .left_s {
    width: calc(35.5% - var(--gap_x));

    .search_bar {
      width: 100%;
      margin-bottom: 15px;
    }

    .MuiFormGroup-root {
      .MuiCheckbox-root {
        &::before {
          width: 26px;
          height: 26px;
        }
      }

      .MuiFormControlLabel-root {
        .MuiFormControlLabel-label {
          font-size: 13px;
          font-family: $f_Candor !important;
          font-weight: 500;
        }
      }
    }
  }

  .right_s {
    width: calc(64.5% - var(--gap_x));

    h2 {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .form {
      .control_group {
        margin-bottom: 20px;
      }

      .MuiFormControl-root :is(input) {
        background-color: $c_highlight;
        border-color: $c_highlight;

        &:focus {
          border-color: $c_secondary;
        }
      }

      .form_btn {
        margin-top: 20px;
        text-align: right;
      }
    }
  }
}

.rdw-editor-wrapper {
  padding-top: 20px;
  background-color: $c_highlight;
  border-radius: 5px;

  .rdw-editor-toolbar {
    border: none !important;
    margin: 0;
    padding: 6px 15px 0px;
    max-width: calc(100% - 40px);
    margin-left: 20px;
    border-radius: 40px;
    box-shadow: $shadow3;
  }

  .rdw-editor-main {
    border: none !important;
    padding: 15px 20px 20px !important;
    height: 230px !important;
  }
}

.chat_grid {
  .cards {
    height: 100%;
  }

  .left_s {
    width: calc(35.5% - var(--gap_x));

    .search_bar {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .right_s {
    width: calc(64.5% - var(--gap_x));

    .cards {
      padding: 0;
    }
  }

  .userChat_list {
    .single {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      z-index: 1;
      gap: 10px;

      &::before {
        content: "";
        inset: -10px;
        position: absolute;
        background-color: $c_highlight;
        transition: $transition;
        transform: scale(0.6);
        pointer-events: none;
        border-radius: 5px;
        z-index: -1;
        opacity: 0;
      }

      figure {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .info {
        flex: 1;
        width: calc(100% - 50px);
        position: relative;

        .count {
          position: absolute;
          right: 0;
          bottom: -4px;
          height: 21px;
          min-width: 21px;
          border-radius: 30px;
          background-color: $c_primary;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $c_white;
          font-size: 11px;
          font-weight: 500;
        }

        h2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;

          b {
            font-size: 15px;
            font-weight: 500;
            color: $c_black;
            font-family: $f_Candor !important;
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          span {
            color: #717171;
            font-size: 11px;
            font-weight: 400;
            display: inline-block;
            white-space: nowrap;
          }
        }

        p {
          color: #717171;
          font-size: 12px;
          font-weight: 400;
          font-family: $f_Candor !important;
        }
      }

      &:hover {
        &::before {
          transform: scale(1);
          opacity: 1;
        }
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

.chat_card {
  display: flex;
  flex-direction: column;

  .chat_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;
    padding: 20px 30px;

    .left {
      display: inline-flex;
      align-items: center;
      gap: 10px;

      figure {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .info {
        flex: 1;
        width: calc(100% - 50px);
        position: relative;
        display: flex;
        flex-direction: column;

        h2 {
          font-size: 15px;
          font-weight: 500;
          color: $c_black;
          font-family: $f_Candor !important;
        }

        p {
          color: #717171;
          font-size: 12px;
          font-weight: 400;
          font-family: $f_Candor !important;
          display: inline-flex;
          align-items: center;
          line-height: 1;
          gap: 5px;

          span {
            display: inline-flex;
            width: 6px;
            height: 6px;
            border-radius: 50%;

            &.active {
              background-color: #02d03c;
            }

            &.inactive {
              background-color: $c_red;
            }
          }
        }
      }
    }

    .right {
      .MuiIconButton-root {
        padding: 10px 0;
        background-color: transparent;

        .MuiTouchRipple-root {
          display: none;
        }

        img {
          transition: $transition;
        }

        &:hover {
          img {
            filter: brightness(0);
          }
        }
      }
    }
  }

  .chat_body {
    flex: 1;
    padding: 30px 30px 0;
    max-height: calc(100vh - 386px);
    min-height: 200px;
    overflow: auto;

    .typing {
      display: flex;
      align-items: center;
      padding: 5px 15px;
      gap: 5px;

      span {
        height: 7px;
        width: 7px;
        display: inline-flex;
        background-color: #d6d6d6;
        animation: wave-text 1s ease-in-out infinite;
        border-radius: 50%;

        &:nth-of-type(1) {
          animation-delay: 0s;
        }

        &:nth-of-type(2) {
          animation-delay: 0.1s;
        }

        &:nth-of-type(3) {
          animation-delay: 0.2s;
        }
      }
    }

    @keyframes wave-text {
      0% {
        transform: translateY(0em);
      }

      60% {
        transform: translateY(-0.6em);
      }

      100% {
        transform: translateY(0em);
      }
    }

    .single_message {
      max-width: 350px;

      &:not(:last-child) {
        margin-bottom: 25px;
      }

      .msg_head {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 15px;

        figure {
          height: 40px;
          width: 40px;
          min-width: 40px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h2 {
          font-size: 15px;
          color: $c_black;
          font-weight: 500;
          display: inline-flex;
          align-items: center;
          font-family: $f_Candor !important;
          gap: 12px;

          span {
            font-size: 11px;
            font-weight: 400;
            color: #717171;
            font-family: $f_Poppins !important;
          }
        }
      }

      .msg_body {
        padding: 15px;
        background-color: $c_highlight;
        display: inline-block;
        width: auto;

        p {
          font-size: 12px;
          color: #030302;
          font-family: $f_Candor !important;
        }
      }

      &.sender {
        .msg_body {
          border-radius: 0 8px 8px 8px;
        }
      }

      &.reciever {
        margin-left: auto;

        .msg_head {
          flex-direction: row-reverse;

          h2 {
            flex-direction: row-reverse;
          }
        }

        .msg_body {
          border-radius: 8px 0 8px 8px;
        }
      }
    }
  }

  .chat_foot {
    display: flex;
    align-items: center;
    position: relative;
    padding: 30px;
    gap: 15px;

    > figure {
      height: 50px;
      width: 50px;
      min-width: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .MuiFormControl-root :is(input) {
      border-radius: 40px !important;
      background-color: $c_highlight;
      border-color: $c_highlight;
      padding: 5px 200px 5px 30px;
      font-family: $f_Candor;
      height: 50px;

      &:focus {
        border-color: $c_secondary;
      }

      &::placeholder {
        color: #717171;
      }
    }

    .more_items {
      position: absolute;
      right: 95px;
      top: 50%;
      transform: translate(0, -50%);
      display: inline-flex;
      align-items: center;

      .MuiIconButton-root {
        padding: 0;

        &:not(:first-child) {
          margin-left: 15px;
        }

        &.addIcon {
          height: 21px;
          width: 21px;
          border-radius: 50%;
          background-color: #dde0e1;

          svg {
            font-size: 18px;
            color: $c_black;
          }
        }
      }
    }

    .chat_submit {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translate(0, -50%);
      height: 40px;
      width: 40px;
      min-width: 40px;
      background-color: $c_white;
      border-radius: 50%;
    }
  }
}

.profile_top {
  .left_s {
    width: 68%;
    display: flex;
    align-items: center;
    gap: 25px;
  }

  .right_s {
    width: 32%;
  }

  .profile_image {
    width: 142px;
    min-width: 142px;

    figure {
      width: 100%;
      aspect-ratio: 1/1;
      position: relative;
      margin-bottom: 12px;
      display: flex;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      span {
        right: 5px;
        bottom: 5px;
        display: flex;
        position: absolute;
        transform: translate(50%, 50%);

        img {
          box-shadow: $shadow3;
        }
      }
    }

    p {
      color: $c_grey;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
    }
  }

  .profile_info {
    h2 {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      gap: 20px;

      b {
        font-size: 25px;
        display: inline-block;
        font-weight: 500;
      }

      span {
        font-size: 15px;
        font-weight: 400;
        display: inline-flex;
        align-items: center;
        line-height: 1;
        gap: 5px;

        svg {
          color: $c_secondary;
        }
      }
    }

    .text_list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0 0 20px;

      li {
        color: #717171;
        font-size: 14px;
        font-weight: 400;
        display: inline-flex;
        align-items: center;
        list-style: none;
        gap: 8px;

        &:not(:last-child) {
          &::after {
            content: "";
            display: inline-flex;
            height: 24px;
            width: 1px;
            background-color: #ededed;
            margin: 0 15px;
          }
        }
      }
    }

    .box_list {
      display: flex;
      flex-wrap: wrap;
      gap: 13px;
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        border-radius: 8px;
        padding: 12px 15px;
        border: 1px dashed #ededed;
        min-width: 150px;

        h3 {
          font-size: 18px;
          font-weight: 600;
          display: inline-flex;
          align-items: center;
          margin-bottom: 6px;
          line-height: 1;
          gap: 5px;

          img {
            position: relative;
            top: -1px;
          }
        }

        p {
          font-size: 12px;
          color: $c_grey;
        }
      }
    }
  }

  .social_icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;
    gap: 10px;

    .MuiIconButton-root {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $c_highlight;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: $transition;

      img {
        height: 16px;
        transition: $transition;
      }

      &:hover {
        background-color: $c_secondary;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  .progress_info {
    h3 {
      font-size: 14px;
      font-weight: 500;
      color: #767676;
      margin-bottom: 2px;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    > div {
      display: flex;
      align-items: center;
      pointer-events: none;

      .MuiSlider-root {
        padding: 8px 0;

        .MuiSlider-rail {
          height: 7px;
          background-color: #ccd2e3;
          border-radius: 15px;
        }

        .MuiSlider-track {
          border-radius: 15px;
          background-color: $c_secondary;
          border: none;
          height: 7px;
        }

        .MuiSlider-thumb {
          width: 18px;
          height: 18px;
          background-color: $c_secondary;

          &::before {
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          }
        }
      }

      .MuiInputBase-root {
        &::before,
        &::after {
          display: none;
        }

        input {
          padding: 0;
          font-size: 16px;
          font-weight: 500;
          color: #121521;
          text-align: right;
          min-width: 60px;
          appearance: none;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &[type="number"] {
            appearance: textfield;
            -moz-appearance: textfield;
          }
        }
      }
    }
  }
}

.profileInfo {
  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    h2 {
      font-size: 16px;
      font-weight: 600;
    }

    .MuiButton-root.btn {
      height: 40px;
    }
  }

  &_list {
    h3 {
      font-size: 16px;
      font-weight: 600;
      padding: 50px 0 20px;
    }

    .text_info {
      &.gap_p {
        --gap_x: 20px;
        --gap_y: 15px;
      }

      li {
        gap: 10px;
        display: flex;
        list-style: none;
        font-size: 14px;
        word-break: break-word;

        strong {
          font-weight: 500;
          color: #2c2c2c;
          display: inline-flex;
          min-width: 42%;
        }

        span {
          font-weight: 400;
          color: #717171;
          display: inline-flex;
          align-items: flex-start;
          gap: 5px;

          &.cursor_p {
            img {
              position: relative;
              top: 2px;
            }

            &:hover {
              color: $c_secondary;

              img {
                filter: brightness(0%) invert(54%) sepia(18%) saturate(1396%)
                  hue-rotate(75deg) brightness(98%) contrast(93%);
              }
            }
          }
        }
      }
    }
  }
}

.invitedPersons_info {
  .text_list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 20px;

    li {
      color: $c_grey;
      font-size: 13px;
      font-weight: 400;
      display: inline-flex;
      align-items: center;
      list-style: none;
      gap: 8px;

      b {
        color: $c_black;
        font-weight: 600;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          display: inline-flex;
          height: 14px;
          width: 1px;
          background-color: #ededed;
          margin: 0 20px;
        }
      }
    }
  }

  .table_container table.v2 tr td {
    color: $c_grey;
  }
}

.review_grid {
  .single_review {
    padding: 20px 25px;
    position: relative;
    width: calc(100% / 2 - var(--gap_x));
    border: 1px solid #ededed;
    border-radius: 10px;
    overflow-wrap: anywhere;

    figure {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 10px;

      > img {
        height: 50px;
        width: 50px;
        min-width: 50px;
        border-radius: 50%;
        object-fit: cover;
      }

      figcaption {
        display: flex;
        flex-direction: column;
        padding-right: 70px;
        flex: 1;
      }
    }

    h2 {
      display: flex;
      align-items: center;
      gap: 15px;

      strong {
        color: $c_black;
        font-size: 16px;
        font-weight: 500;
      }

      span {
        font-size: 12px;
        color: $c_black;
        font-weight: 400;
        display: inline-flex;
        align-items: center;
        gap: 5px;
      }
    }

    .MuiRating-root {
      color: #f96700 !important;

      label {
        color: #f96700 !important;
        margin: 0;
      }

      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }

    > p {
      font-size: 14px;
      color: $c_grey;
    }

    .floating_btns {
      position: absolute;
      right: 20px;
      top: 20px;
      display: flex;
      align-items: center;
      gap: 15px;

      .MuiIconButton-root {
        padding: 0;
        background-color: transparent !important;

        .MuiTouchRipple-root {
          display: none;
        }

        img {
          transition: $transition;
        }

        &:hover {
          img {
            filter: brightness(0);
          }
        }
      }
    }
  }
}

.shipping_img {
  margin-top: 30px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;

    li {
      list-style: none;
      min-width: 95px;
      max-width: 95px;

      figure {
        width: 100%;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 5px;
        border: 1px solid #ededed;
        cursor: pointer;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .table_container {
    margin-top: 30px;

    table.v2 tr td {
      color: $c_grey;
    }

    .view_link {
      color: $c_secondary;
      display: inline-flex;
      align-items: center;
      text-decoration: underline;
      vertical-align: middle;
      cursor: pointer;
      gap: 5px;

      img {
        filter: brightness(0) invert(53%) sepia(79%) saturate(401%)
          hue-rotate(75deg) brightness(89%) contrast(82%);
        height: 13px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.product_gallery,
.product_thumb {
  .slick-slide > div,
  .slick-slide > div {
    display: flex;
  }

  .slick-list {
    margin: 0 -8px;
  }

  .slick-slide {
    padding: 0 8px;
  }

  .product_image {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 24px;
    background-color: #e2e2e2;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.product_gallery {
  margin-bottom: 50px;
}

.product_thumb {
  .product_image {
    border-radius: 7px;
    cursor: pointer;
  }

  .slick-slide {
    &:not(.slick-current) {
      .product_image {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          inset: 0;
          background-color: $c_black;
          opacity: 0.8;
        }
      }
    }
  }
}
.err_msg {
  margin-left: "5px";
  color: red !important;
  font-size: 14px !important;
  margin: 0 !important;
  font-weight: 400;
}
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "regular";
    font-size: 18px;
  }
  p {
    font-size: 14px;
    font-family: "regular";
    span {
      font-family: "bold";
      color: black;
    }
  }
  .pages {
    display: flex;
    .prevbtn {
      background: no-repeat;
      border: none;
    }
    svg {
      font-size: 12px;
      width: 20px;
      height: 20px;
      border: 0.5px solid #a5a5a5;
      padding: 3px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-right: 10px;
      cursor: pointer;
      color: black;
    }
    .count {
      display: flex;
      align-items: center;
      margin-right: 10px;
      background: none;
      /* padding: 6px; */
      border-radius: 24px;
      border: 0.5px solid #fff;
      .actv {
        background: $c_secondary;
        font-weight: 300;
        color: rgb(0, 0, 0);
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
      }
      .inActv {
        background: transparent;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: black;
        cursor: pointer;
        border: none;
      }
    }
  }
}
.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;
  figure {
    width: 60px;
    height: 60px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
.slider-2000 {
  // width: 200px;
  // height: 200px;
  .slick-next:before {
    color: black;
    margin-left: 15px;
    font-size: xx-large;
  }
  .slick-prev:before {
    color: black;
    margin-right: 15px;
    font-size: xx-large;
    margin-left: -35px;
  }
  // padding: 0;
}
.modal-content12 {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-container12 {
  margin: 10px;
  position: relative;
}

.delete-button12 {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.EditPassportModel {
  background-color: "grey";
}
.svg_rate.MuiRating-root .MuiSvgIcon-root {
  font-size: 40px !important;
  color: #42a74b !important;
}
.black-image-profile {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
  width: 25px;
}
.control_group .brdr_ne input {
  border: none !important;
}
.control_group .brdr_ne fieldset {
  display: none;
}
.control_group .brdr_ne.Mui-expanded .MuiInputBase-formControl {
  border: 1px solid #42a74b;
}
.control_group .brdr_ne .MuiAutocomplete-input {
  padding: 5px 14px !important;
}
.control_group .brdr_ne .MuiInputBase-formControl {
  border: 1px solid #ededed;
  border-radius: 5px !important;
}
.react-tel-input {
  position: relative;
}

.react-tel-input .flag-dropdown {
  border-radius: 3px 0 0 3px;
  bottom: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.react-tel-input .selected-flag {
  outline-color: initial;

  border-radius: 3px 0 0 3px;
  height: 100%;
  outline: none;
  padding: 0 0 0 11px;
  position: relative;
  width: 52px;
}
.react-tel-input .selected-flag:before {
  border: 1px solid #0000;
  border-radius: 4px 2px 2px 4px;
  bottom: 2px;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 2px;
  transition: box-shadow 0.25s ease, border-color 0.25s ease;
  width: 100%;

  border-color: rgba(140, 130, 115, 0);
}
.react-tel-input .selected-flag .flag {
  margin-top: -12px;
  position: absolute;
  top: 50%;
}
.react-tel-input .selected-flag .arrow {
  border-left-color: rgba(140, 130, 115, 0);
  border-right-color: rgba(140, 130, 115, 0);
  border-top-color: rgb(112, 104, 92);
  border-left: 3px solid rgba(0, 0, 0, 0);
  border-right: 3px solid rgba(0, 0, 0, 0);
  border-top: 4px solid #555;
  height: 0;
  left: 29px;
  /* margin-top: -1px; */
  position: absolute;
  /* top: -590%; */
  top: 20px;
  width: 0;
}
// .country-list
// {}
.search-emoji {
  display: none !important;
}
.user_view_label {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.checkbox-wrapper-25 input[type="checkbox"] {
  background-image: -webkit-linear-gradient(
      hsla(0, 0%, 0%, 0.1),
      hsla(0, 0%, 100%, 0.1)
    ),
    -webkit-linear-gradient(left, #42a74b 50%, #4e148c 50%);
  background-size: 100% 100%, 200% 100%;
  background-position: 0 0, 15px 0;
  border-radius: 25px;
  box-shadow: inset 0 1px 4px hsla(0, 0%, 0%, 0.5),
    inset 0 0 10px hsla(0, 0%, 0%, 0.5), 0 0 0 1px hsla(0, 0%, 0%, 0.1),
    0 -1px 2px 2px hsla(0, 0%, 0%, 0.25), 0 2px 2px 2px hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  height: 25px;
  padding-right: 25px;
  width: 75px;
  -webkit-appearance: none;
  -webkit-transition: 0.25s;
  margin-inline: 10px;
}

.checkbox-wrapper-25 input[type="checkbox"]:after {
  background-color: #eee;
  background-image: -webkit-linear-gradient(
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 0%, 0.1)
  );
  border-radius: 25px;
  box-shadow: inset 0 1px 1px 1px hsla(0, 0%, 100%, 1),
    inset 0 -1px 1px 1px hsla(0, 0%, 0%, 0.25),
    0 1px 3px 1px hsla(0, 0%, 0%, 0.5), 0 0 2px hsla(0, 0%, 0%, 0.25);
  content: "";
  display: block;
  height: 25px;
  width: 50px;
  /* margin-inline: 10px; */
}

.checkbox-wrapper-25 input[type="checkbox"]:checked {
  background-position: 0 0, 35px 0;
  padding-left: 25px;
  padding-right: 0;
  margin-inline: 10px;
}
@media (max-width: 767px) {
  :is(input[type="checkbox"]) {
    height: 25px !important;
  }
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
