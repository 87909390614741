$c_primary: #4e148c;
$c_primary_light: #42a74b1c;
$c_secondary: #42a74b;
$c_primary_hover: #133524;
$c_form_border: #ededed;
$c_white: #fff;
$c_black: #000;
$c_orange: #f96700;

$c_success: #008000;
$c_success_bg: #dcffcc;

$c_warning: #ffa500;
$c_warning_bg: #fff3dc;

$c_red: #ff0000;
$c_red_hover: #c20303;
$c_red_bg: #fff1f1;

$c_info: #0000ff;
$c_info_bg: #ebebff;

$c_text: #b5b5c3;
$c_text_grey: #999;
$c_heading: #212121;
$c_highlight: #f5f8fa;
$c_grey: #979797;
$c_grey_light: #e7e7e7;
$c_grey_dark: #030302;
$shadow: rgba(0, 0, 0, 0.08) 0px 2px 25px 0px;
$shadow2: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
$shadow3: 0px 4px 30px 0px rgba(0, 0, 0, 0.13);
$f_Poppins: "Poppins", sans-serif;
$f_Candor: "BR Candor";
$transition: all 0.3s ease-in-out 0s;
