@import '../../variable.scss';

.authContainer {
    // height: 100vh;
    height: 100%;
    background-color: $c_primary;
    box-sizing: border-box;

    .container-auth {
        margin-inline: auto;
        max-width: 1200px;
        width: 90%;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .authpage {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-block: 50px;
            width: 100%;

            .authLeft {
                width: 50%;
                padding-right: 50px;

                figure {
                    width: 420px;
                }
            }

            .authRight {
                width: 50%;

                .authBox {
                    border-radius: 9px;
                    padding: 50px;
                    background: white;

                    h2 {
                        margin: 0;
                        font-size: 32px;
                        text-align: center;
                    }

                    p {
                        margin: 0;
                        font-size: 16px;
                        color: $c_text_grey;
                        // text-align: center;
                        // padding-bottom: 40px;
                        padding-top: 5px;
                    }
                    .Gina {
                        margin: 0;
                        font-size: 16px;
                        color: $c_text_grey;
                        text-align: center;
                        padding-bottom: 40px;
                        padding-top: 5px;
                    }

                    form {
                        .form_control.d_flex {
                            align-items: center;
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 30px;

                            .anchor_link {
                                color: $c_secondary;
                                transition: all 0.3s ease-in-out 0s;
                                cursor: pointer;
                                font-size: 16px;
                                text-decoration: none;
                            }
                        }

                        .forgotMsg {
                            display: flex;
                            align-items: center;
                            background: $c_primary_light;
                            padding: 15px;
                            border-radius: 6px;
                            margin-bottom: 40px;

                            figure {
                                margin: 0;
                                width: 35px;
                                height: 35px;
                            }

                            .sucdiv {
                                padding-left: 20px;

                                h3 {
                                    font-size: 16px;
                                    margin: 0;
                                    color: $c_secondary;
                                    font-weight: 500;
                                }

                                h4 {
                                    margin: 0;
                                    font-size: 14px;
                                    color: $c_secondary;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}